import * as PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';

const PUBLIC_AWS_WAF_SCRIPT =
  import.meta.env.PUBLIC_AWS_WAF_SCRIPT ??
  'https://1616f08f173b.edge.sdk.awswaf.com/1616f08f173b/77a3f8a6433a/challenge.js';

const AwsWafContext = createContext({ loaded: false, error: false });

export const AwsWafProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (document.querySelector(`script[src="${PUBLIC_AWS_WAF_SCRIPT}"]`)) {
      setLoaded(true);
      return;
    }

    const script = document.createElement('script');
    script.src = PUBLIC_AWS_WAF_SCRIPT;
    script.type = 'text/javascript';
    script.defer = true;

    script.onload = () => setLoaded(true);

    script.onerror = () => setError(true);

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <AwsWafContext.Provider value={{ loaded, error }}>{children}</AwsWafContext.Provider>;
};

export const useAwsWaf = () => {
  return useContext(AwsWafContext);
};

AwsWafProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
