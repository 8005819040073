import { GO_CARDLESS_REDIRECT_PARAM } from '~/lib/constants';
import { TOAST_STATUS } from '~/lib/message';

export function linkBankAccountFactory({
  GOCARDLESS,
  accountState,
  db,
  fetchUrl,
  getErrorMessage,
  logEvent,
  makeSearchInst,
  message,
  responseStatusIsGood,
  userCardState,
}) {
  return {
    $value: {
      isLoaded: false,
      isCardLinked: false,
      postModalUrl: '',
      isModalOpen: false,
    },
    selectors: {},
    actions: {
      async reset(state) {
        state.do.set_isLoaded(false);
        state.do.setIsCardLinked(false);
        const database = await db;
        await database.gocardlessinstitutions.clearAllRecords();
      },
      async checkForRef(state, didProcessParamRef, navigate) {
        const searchParams = new URLSearchParams(window.location.search);
        let refId = searchParams.get(GO_CARDLESS_REDIRECT_PARAM);
        if (refId) {
          didProcessParamRef.current = refId;
          await state.do.postSuccessLinkCard(refId);
        }

        state.do.navAfterCloseModal(navigate);
      },
      navAfterCloseModal(state, navigate) {
        if (state.value.postModalUrl) {
          navigate(state.value.postModalUrl);
        } else {
          const url = new URL(window.location.href);
          url.searchParams.delete(GO_CARDLESS_REDIRECT_PARAM);
          const toNavigate = `${url.pathname}?${url.searchParams.toString()}`;
          navigate(toNavigate);
        }

        state.do.set_postModalUrl('');
      },
      setIsCardLinked(state, isCardLinked) {
        state.do.set_isCardLinked(isCardLinked);
      },
      async loadInstitutions(state) {
        const database = await db;
        if (state.value.isLoaded) return;
        const { searchText } = state.value;
        try {
          await database.gocardlessinstitutions.fetchAll(searchText.searchInstText);
          state.do.set_isLoaded(true);
        } catch (fetchErr) {
          message({
            title: 'Cannot Load institutions.',
            description: getErrorMessage(fetchErr.message),
            status: 'error',
          });
        }
      },
      async navigateInstitutionUrl(state, institution_id) {
        logEvent('GoCardLess', { action: 'link_card' });
        if (accountState.$.isExpired()) {
          message({
            title: 'Cannot Link',
            description: 'Your session has expired; please log in again',
            status: 'error',
          });
          return;
        }

        try {
          const response = await fetchUrl(
            `${GOCARDLESS}/redirect/${institution_id}?redirectUrl=${window.location.href}`,
            {
              accountState,
            }
          );
          const data = await response.json();

          if (data?.link_card_redirect_url) {
            window.location.href = data.link_card_redirect_url;
          }

          if (!responseStatusIsGood(response)) {
            throw new Error(data?.message || `bad status ${response.status}`);
          }
        } catch (err) {
          message({
            title: 'Cannot Link',
            description: getErrorMessage(err.message),
            status: 'error',
          });
        }
      },
      async postSuccessLinkCard(state, refId) {
        if (accountState.$.isExpired()) {
          message({
            title: 'Cannot Link',
            description: 'Your session has expired; please log in again',
            status: 'error',
          });
          return;
        }

        if (state.value.isCardLinked) return;

        try {
          const response = await fetchUrl(`${GOCARDLESS}/link-successful-cb/${refId}`, {
            accountState,
          });
          const data = await response.json();
          if (!responseStatusIsGood(response)) {
            throw new Error(data?.message || `bad status ${response.status}`);
          }

          if (data) {
            state.do.setIsCardLinked(true);
            message({
              title: 'Card Linked',
              description: data?.message || 'Card linked successfully',
              status: TOAST_STATUS.SUCCESS,
            });
            userCardState.do.loadCards();
          }
        } catch (error) {
          message({
            title: 'Card NOT Linked',
            status: 'error',
            description: getErrorMessage(error.message),
          });
        }
      },
      async unLinkCard(state, institutionId) {
        logEvent('GoCardLess', { action: 'unlink_card' });
        if (accountState.$.isExpired()) {
          message({
            title: 'Cannot Link',
            description: 'Your session has expired; please log in again',
            status: 'error',
          });
          return;
        }

        try {
          const response = await fetchUrl(`${GOCARDLESS}/${institutionId}`, {
            accountState,
            method: 'DELETE',
          });
          const data = await response.json();

          message({
            title: 'Card unlinked',
            status: TOAST_STATUS.SUCCESS,
            description: data?.message,
          });

          if (!responseStatusIsGood(response)) {
            throw new Error(data?.message || `bad status ${response.status}`);
          }
          return userCardState.do.loadCards();
        } catch (err) {
          message({
            title: 'Cannot Unlink card',
            description: getErrorMessage(err.message),
            status: 'error',
          });
        }
      },
    },
    children: {
      searchText: makeSearchInst(),
    },
  };
}
