'use client';

import { addRxPlugin, createRxDatabase } from 'rxdb';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { getRxStorageMemory } from 'rxdb/plugins/storage-memory';
import { goCardLessInstitutionsSchema } from '~/lib/database/schema/gocardless-institutions/goCardLess-institutions-schema';

addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBDevModePlugin);
addRxPlugin(RxDBQueryBuilderPlugin);

export async function getDatabase() {
  const myDatabase = await createRxDatabase({
    name: 'white-label',
    storage: getRxStorageMemory(),
    ignoreDuplicate: true,
  });

  try {
    await myDatabase.addCollections({
      gocardlessinstitutions: goCardLessInstitutionsSchema,
    });
  } catch (err) {
    console.warn('RXDB error:', err);
  }

  return myDatabase;
}

const db = getDatabase();

// @TODO: do a watch of accountState for logging off, purge all records.

export default db;
