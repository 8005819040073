import { Box, Center, HStack, Text } from '@chakra-ui/react';
import Color from 'color';
import * as PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import { DeleteIcon } from '~/components/icons';
import InstitutionLogo from '~/components/inst-logo';
import { useConfig } from '~/hooks/useConfig';
import { useFinance } from '~/hooks/useFinance';
import { LINKED_CARDS_ERROR, REGIONS } from '~/lib/constants';
import { getRegion } from '~/lib/helpers';
import linkBankAccountState from '~/lib/state/link-bank-account/link-bank-account-state';
import { colors } from '~/theme/default';

const disabledCardColor = '#636363';
export function CreditCardDetails({ card, institution }) {
  const config = useConfig();
  const region = getRegion(config);
  const { reAuth, unlinkCard } = useFinance();

  const unlinkCreditCard = useCallback(() => {
    if (region === REGIONS.US) {
      unlinkCard(institution, institution?.institution_id);
    } else if (region === REGIONS.UK) {
      linkBankAccountState.do.unLinkCard(card?.institution_id);
    }
  }, [card?.institution_id, institution, region, unlinkCard]);

  const gradient = useMemo(() => {
    let startColor = institution?.error ? '#888888' : '#3871C8';
    let endColor = institution?.error ? '#E7E7E7' : '#8EE3EE';

    let startColorObj = Color(startColor);
    let endColorObj = Color(endColor);

    if (institution && institution?.color) {
      const instColorObj = Color(institution?.error ? disabledCardColor : institution.color);
      startColorObj = instColorObj.darken(0.2);
      endColorObj = instColorObj.lighten(0.2);

      startColor = startColorObj.hex();
      endColor = endColorObj.hex();
    }

    return `linear(to-br, 
    ${startColor},
    ${startColor},
    ${startColorObj.mix(endColorObj, 0.5).hex()}, 
    ${endColor},
     ${endColor})`.replace(/\n/g, '');
  }, [institution]);

  if (institution?.error === LINKED_CARDS_ERROR.NEED_AUTHENTICATION) {
    return (
      <Box
        bgGradient={gradient}
        borderRadius='8px'
        cursor='pointer'
        height='112px'
        my={2}
        position='relative'
        width='168px'
        flexShrink={0}
      >
        <Box position='absolute' left={2} top={2}>
          <Text fontSize='10px' color='white' fontWeight='400'>
            {institution?.institution_name}
          </Text>
        </Box>
        <Center>
          <HStack
            alignItems='center'
            bg='red.50'
            borderRadius='8px'
            height='32px'
            justifyContent='center'
            onClick={() => reAuth(institution.institution_id)}
            onKeyDown={({ key }) => {
              if (key === 'Enter') {
                reAuth(institution.institution_id);
              }
            }}
            padding={2}
            position='absolute'
            role='button'
            tabIndex={0}
            top='35%'
            width='112px'
          >
            <FiRefreshCw color={colors.red[300]} />
            <Text color='red.300' fontSize='12px' fontWeight='500'>
              Re-connect
            </Text>
          </HStack>
        </Center>

        <Box position='absolute' bottom={1} right={2}>
          <InstitutionLogo institution={institution} />
        </Box>
        <Box
          position='absolute'
          bottom={1}
          left={2}
          w='24px'
          h='24px'
          onClick={unlinkCreditCard}
          role='button'
          tabIndex={0}
          onKeyDown={({ key }) => {
            if (key === 'Enter') {
              unlinkCreditCard();
            }
          }}
        >
          <DeleteIcon />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      bgGradient={gradient}
      borderRadius='8px'
      cursor='pointer'
      height='112px'
      my={2}
      position='relative'
      width='168px'
      flexShrink={0}
    >
      <Box position='absolute' left={2} top={1}>
        <Text color='white' fontSize='10px' fontWeight='400'>
          {card.name}
        </Text>
      </Box>
      {card.mask && (
        <HStack justifyContent='center' height='100%' alignContent='center'>
          <Box>
            <Text color='white' fontSize='10px' fontWeight='400' textAlign='center'>
              •••• •••• •••• {card.mask || '••••'}
            </Text>
          </Box>
        </HStack>
      )}
      <Box position='absolute' bottom={1} right={2}>
        <HStack>
          <Text color='white' fontSize='10px' fontWeight='400'>
            {institution?.institution_name}
          </Text>
          <InstitutionLogo institution={institution} />
          {institution?.logo}
        </HStack>
      </Box>
      <Box
        position='absolute'
        bottom={1}
        left={2}
        w='24px'
        h='24px'
        onClick={unlinkCreditCard}
        role='button'
        tabIndex={0}
        onKeyDown={({ key }) => {
          if (key === 'Enter') {
            unlinkCreditCard();
          }
        }}
      >
        <DeleteIcon />
      </Box>
    </Box>
  );
}

CreditCardDetails.propTypes = {
  card: PropTypes.object,
  institution: PropTypes.object,
};
