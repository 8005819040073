import {
  Box,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { FaChevronRight } from 'react-icons/fa6';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useGlobalForest from '~/hooks/useGlobalForest';
import useLoadInstitutions from '~/hooks/useLoadInstitutions';
import { GO_CARDLESS_REDIRECT_PARAM } from '~/lib/constants';
import globalState from '~/lib/state/global-state';
import linkBankAccountState from '~/lib/state/link-bank-account/link-bank-account-state';
import observeSearchInstitutions from './observe-search-institutions';

export default function LinkBankAccountModal() {
  const navigate = useNavigate();
  const { publisher_home_url } = useGlobalForest(globalState);
  const lbaState = useGlobalForest(linkBankAccountState);
  const theme = useTheme();
  const { isModalOpen } = lbaState;
  const instRef = useRef(null);
  const didProcessParamRef = useRef(null);
  const institutions = useLoadInstitutions(instRef);
  const searchInstState = linkBankAccountState.child('searchText');
  const { searchInstPhrase } = useGlobalForest(searchInstState);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const unsubscribe = observeSearchInstitutions(linkBankAccountState);
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!searchParams.has(GO_CARDLESS_REDIRECT_PARAM)) {
      didProcessParamRef.current = null;
    }
  }, [searchParams]);

  useEffect(() => {
    if (didProcessParamRef.current) {
      return;
    }

    linkBankAccountState.do.checkForRef(didProcessParamRef, navigate);
  }, [navigate]);

  const handleInstitutionClick = (institution) => {
    if (institution) {
      linkBankAccountState.do.navigateInstitutionUrl(institution.id, publisher_home_url, navigate);
    }
  };

  return (
    <Modal
      data-role='inst-modal-container'
      isOpen={isModalOpen}
      onClose={() => linkBankAccountState.do.set_isModalOpen(false)}
      isCentered
      layerStyle='inst-modal-container'
    >
      <ModalOverlay />
      <ModalContent
        data-role='inst-modal-content'
        layerStyle='inst-modal-content'
        px='60px'
        py='80px'
      >
        <ModalHeader data-role='inst-modal-title'>
          <Text textStyle='inst-modal-title'>Select Your Account</Text>
        </ModalHeader>
        <ModalCloseButton />
        <Box layerStyle='inst-modal-search'>
          <InputGroup variant='searchInst'>
            <InputLeftAddon backgroundColor='white'>
              <img alt='search icon' src='/icons/text-search.svg' />
            </InputLeftAddon>
            <Input
              layerStyle='inst-modal-input'
              name='searchText'
              placeholder='search...'
              onChange={searchInstState.do.handleSearchInstField}
              value={searchInstPhrase ?? ''}
            />
          </InputGroup>
        </Box>
        <ModalBody data-role='inst-modal-body' layerStyle='inst-modal-body'>
          <SimpleGrid
            columns={[1, 1, 2]}
            data-role='inst-modal-grid'
            paddingRight='10px'
            spacingY='10px'
            spacingX='10px'
          >
            {(institutions ?? []).map((institution) => (
              <Box
                key={institution?.id}
                data-role='inst-modal-box'
                layerStyle='inst-modal-box'
                onClick={() => handleInstitutionClick(institution)}
              >
                <HStack>
                  {institution.logo && (
                    <Image alt='logo' src={institution.logo} layerStyle='inst-modal-logo' />
                  )}
                  <Text noOfLines={[1, 1, 2]} textStyle='inst-modal-name'>
                    {institution.name}
                  </Text>
                  <FaChevronRight
                    color={theme.colors.secondary.primary}
                    style={{ marginLeft: 'auto' }}
                  />
                </HStack>
              </Box>
            ))}
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
