import { useEffect, useState } from 'react';
import observeSearchInstitutions from '~/components/link-bank-account-modal/observe-search-institutions';
import db from '~/lib/database/db';
import linkBankAccountState from '~/lib/state/link-bank-account/link-bank-account-state';

export default function useLoadInstitutions(instRef) {
  const [institutions, setInstitutions] = useState(null);

  useEffect(() => {
    const fetchAndObserveInstitutions = async () => {
      const database = await db;
      const records = await database.gocardlessinstitutions.find({});

      if (!records.length) {
        await database.gocardlessinstitutions.fetchAll();
      } else {
        setInstitutions(records);
      }

      const query = database.gocardlessinstitutions.find();
      instRef.current = query.$.subscribe((v) => setInstitutions(v));
    };

    fetchAndObserveInstitutions();

    const unsubscribeObservations = observeSearchInstitutions(linkBankAccountState);

    return () => {
      if (instRef.current) {
        instRef.current.unsubscribe();
      }
      unsubscribeObservations();
    };
  }, [instRef]);

  return institutions;
}
