import { Spinner } from '@chakra-ui/react';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import Chakra from '~/components/Chakra';
import ContactSuccessForm from '~/components/footer/contact-success-form';
import { ProtectedRoutes } from '~/components/protected-routes';
import AccountProvider from '~/lib/state/contexts/AccountContext';
import AlertProvider from '~/lib/state/contexts/AlertContext';
import { AwsWafProvider } from '~/lib/state/contexts/AwsWafContext';
import FinanceProvider from '~/lib/state/contexts/FinanceContext';
import { AccountCardsPage, AccountPage, BrandPage, SearchPage, TransactionsPage } from '~/pages';
import BrandsPage from '~/pages/brands/brands-page';
import { ErrorPage } from '~/pages/error-page';
import LandingPage from '~/pages/landing/landing-page';
import PageLayout from '../components/page-layout';
import { BrandNotFoundPage } from './brands/brand-not-found-page';
import { Error404Page } from './error/error-404-page';

const ContactUs = React.lazy(() => import('~/components/footer/contact-us'));

const LazyContactUs = () => (
  <React.Suspense fallback={<Spinner />}>
    <ContactUs />
  </React.Suspense>
);

const router = createBrowserRouter([
  {
    path: '*',
    element: <PageLayout />,
    children: [
      { path: '', element: <LandingPage />, errorElement: <ErrorPage /> },
      {
        path: 'brands',
        element: <Outlet />,
        children: [
          { path: '', element: <BrandsPage />, errorElement: <ErrorPage /> },
          { path: 'not-found', element: <BrandNotFoundPage />, errorElement: <ErrorPage /> },
          {
            path: 'search',
            element: <SearchPage />,
          },
          {
            path: ':brandName/:brandUid',
            element: <BrandPage />,
            errorElement: <ErrorPage />,
          },
          { path: '*', element: <BrandNotFoundPage />, errorElement: <ErrorPage /> },
        ],
      },
      {
        path: 'contact',
        element: <LazyContactUs />,
      },
      {
        path: 'contact/success',
        element: <ContactSuccessForm />,
      },
      {
        path: 'search',
        element: <SearchPage />,
      },
      {
        path: 'reset-password',
        element: <BrandsPage />,
      },
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: 'account',
            element: <AccountPage />,
          },
          {
            path: 'account/cards',
            element: <AccountCardsPage />,
          },
          {
            path: 'account/transactions',
            element: <TransactionsPage />,
          },
        ],
      },
      {
        path: '*',
        element: <Error404Page />,
      },
    ],
  },
]);

export const Router = () => (
  <AwsWafProvider>
    <HelmetProvider>
      <Chakra>
        <AlertProvider>
          <AccountProvider>
            <FinanceProvider>
              <RouterProvider router={router} />
            </FinanceProvider>
          </AccountProvider>
        </AlertProvider>
      </Chakra>
    </HelmetProvider>
  </AwsWafProvider>
);
