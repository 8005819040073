import { neutralizeEvent } from '~/lib/helpers';

export default () => {
  return {
    $value: {
      searchInstPhrase: '',
      searchInstText: '',
    },

    actions: {
      reset(state) {
        state.do.set_searchInstText('');
        state.do.set_searchInstPhrase('');
        state.children.forEach(({ child }) => {
          if (child.do.reset) {
            child.do.reset();
          }
        });
      },
      handleSearchInstField(state, e) {
        neutralizeEvent(e);
        state.do.set_searchInstPhrase(e.target.value);
      },
    },
  };
};
