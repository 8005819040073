import { Forest } from '@wonderlandlabs/forest';
import { GOCARDLESS } from '~/lib/constants';
import db from '~/lib/database/db';
import fetchUrl from '~/lib/fetchUrl';
import { getErrorMessage, responseStatusIsGood } from '~/lib/helpers';
import { message } from '~/lib/message';
import accountState from '~/lib/state/account-state';
import { linkBankAccountFactory } from '~/lib/state/link-bank-account/link-bank-account-factory';
import makeSearchInst from '~/lib/state/link-bank-account/makeSearchInst';

const RESOURCES = {
  GOCARDLESS,
  accountState,
  db,
  fetchUrl,
  getErrorMessage,
  makeSearchInst,
  message,
  responseStatusIsGood,
};
const linkBankAccountState = new Forest(linkBankAccountFactory(RESOURCES));

export default linkBankAccountState;
