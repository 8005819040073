export const financeTranslation = {
  linkedInstitutionsErrorTitle: 'Bad data for linked institutions',
  linkedInstitutionsErrorDescription: 'Cannot find linked institutions',
  linkingCardErrorTitle: 'Error linking card',
  sessionExpiredTitle: 'Session expired',
  sessionExpiredDescription: 'Please log in again',
  cardLinked: 'Card Linked',
  cardUnlinked: 'Card unlinked',
  cardLinkedFromX: (institutionName) => `Linked card from ${institutionName}`,
  cardNotLinked: 'Card NOT linked',
  errorFetchingCards: 'Error fetching cards',
  cannotLink: 'Cannot Link',
  cannotUnlink: 'Cannot unlink card',
  tokenError: 'Token error',
  unlinkCardMessage: (institutionName) =>
    institutionName
      ? `Your card for ${institutionName} is no longer being tracked by our rewards service`
      : 'Your card is no longer being tracked by our rewards service',
  noCards: 'Don’t miss out! Link an account to start earning cash!',
  myWallet: 'My Wallet',
  linkACard: 'Link a card',
  linkMoreCards: 'Link more cards',
  linkedCards: 'Linked Cards',
  addCard: 'Add Card',
  bankAccounts: 'Bank Accounts',
  disconnected: 'Disconnected',
};
