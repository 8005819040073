import { GOCARDLESS } from '~/lib/constants';
import fetchUrl from '~/lib/fetchUrl';
import { responseStatusIsGood } from '~/lib/helpers';
import { message } from '~/lib/message';
import accountState from '~/lib/state/account-state';
import goCardLessInstitutionsFields from './goCardLess-institutions-fields';

let fetching = false;

function makeSearchPhrase(searchParams) {
  return `&searchText=${encodeURIComponent(searchParams)}`;
}

export const goCardLessInstitutionsSchema = {
  statics: {
    async clearAllRecords() {
      const data = await this.find().exec();
      const ids = data.map(({ id }) => id);
      await this.bulkRemove(ids);
    },
    async fetchAll(searchParams) {
      if (fetching) return;
      fetching = true;
      try {
        if (accountState.$.isExpired()) {
          message({
            title: 'Cannot Link',
            description: 'Your session has expired; please log in again',
            status: 'error',
          });
          return;
        }
        const searchPhrase = searchParams ? makeSearchPhrase(searchParams) : '';

        const response = await fetchUrl(`${GOCARDLESS}/institutions?${searchPhrase}`, {
          accountState,
        });

        await this.clearAllRecords();
        const data = await response.json();

        if (!responseStatusIsGood(response)) {
          throw new Error(data?.message || `bad status ${response.status}`);
        }

        fetching = false;
        return this.insertData(data);
      } catch (fetchErr) {
        fetching = false;
        throw fetchErr;
      }
    },
    async insertData(data) {
      if (Array.isArray(data)) {
        try {
          await this.bulkUpsert(data);
        } catch (err) {
          console.error('Cannot save institutions', err.message);
        }
      }
      return data;
    },
  },
  schema: {
    version: 0,
    primaryKey: 'id',
    type: 'object',
    properties: goCardLessInstitutionsFields,
    required: ['id', 'name', 'logo'],
  },
};
