import {
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { FaChevronRight } from 'react-icons/fa6';
import { FLAGS, useFeatureFlag } from '~/hooks/useFeatureFlag';
import { useTranslations } from '~/hooks/useTranslations';
import { SettingsGearsIcon } from '../../icons/settings-gears-icon';
import { PromotionalEmailToggle } from './promotional-email-toggle';
import { settingsModalView } from './settings-modal-container';

export const SettingsModalRoot = ({ setView }) => {
  const theme = useTheme();
  const { enabled: showMarketingConsent } = useFeatureFlag(FLAGS.EDIT_MARKETING_CONSENT);
  const { genericTranslation } = useTranslations();

  return (
    <>
      <ModalHeader display='flex' alignItems='center' flexDirection='column' pt={0}>
        <SettingsGearsIcon color={theme.colors.brand.primary} style={{ marginBottom: '12px' }} />
        <Text
          as='h1'
          fontWeight='medium'
          fontSize='xxl'
          fontFamily='display'
          color='neutral.primary'
        >
          {genericTranslation.settings.settings}
        </Text>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody pt={0} display='flex' flexDirection='column' alignItems='flex-start' gap={4}>
        <VStack gap={2} alignItems='flex-start' width='100%'>
          <Text color='secondary.primary' fontWeight='medium' fontFamily='display' fontSize='sm'>
            {genericTranslation.settings.account}
          </Text>
          <Button
            variant='outline'
            sx={{
              width: '100%',
              height: 16,
              borderColor: 'secondary.tertiary',
              fontWeight: 'medium',
              '& svg, & p': {
                color: 'neutral.primary',
              },
              _hover: {
                ...(theme) => theme.components.Button.variants.outline._hover,
                backgroundColor: 'brand.primary',
                '& svg, & p': { color: 'surface.surface' },
              },
            }}
            onClick={() => {
              setView(settingsModalView.changePassword.form);
            }}
          >
            <HStack width='100%' justifyContent='space-between'>
              <Text fontFamily='display' fontSize='18px'>
                {genericTranslation.settings.changePassword.button}
              </Text>
              <FaChevronRight />
            </HStack>
          </Button>
        </VStack>
        {showMarketingConsent && (
          <PromotionalEmailToggle />
        )}
      </ModalBody>
    </>
  );
};

SettingsModalRoot.propTypes = {
  setView: PropTypes.func.isRequired,
};
