import { debounceTime, distinctUntilChanged, map } from 'rxjs';

export default function observeSearchInstitutions(state) {
  const subInst = state
    .child('searchText')
    .observable.pipe(
      map((v) => v.searchInstPhrase),
      debounceTime(800),
      distinctUntilChanged()
    )
    .subscribe((searchInstText) => {
      state.child('searchText').do.set_searchInstText(searchInstText);
    });

  const subInst1 = state
    .child('searchText')
    .observable.pipe(map((v) => v.searchInstText))
    .subscribe(() => {
      state.do.reset();
      state.do.loadInstitutions();
    });

  return () => {
    subInst?.unsubscribe();
    subInst1?.unsubscribe();
  };
}
