import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Circle,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useBoolean,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { FaChevronRight, FaPlus } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import InstLogo from '~/components/inst-logo';
import { useFinance } from '~/hooks/useFinance';
import useGlobalForest from '~/hooks/useGlobalForest';
import useIsLoggedIn from '~/hooks/useIsLoggedIn';
import { useTranslations } from '~/hooks/useTranslations';
import { ACCOUNT_CARDS_ROUTE, LINKED_CARDS_ERROR, REGIONS } from '~/lib/constants';
import globalState from '~/lib/state/global-state';
import linkBankAccountState from '~/lib/state/link-bank-account/link-bank-account-state';
import { AddCreditCardButton } from '~/pages/account/cards/add-credit-card-button';
import { UserAndCard } from '~/pages/account/user-and-card';
import CreditCardGroup from './credit-card-group';

function InstIcon({ inst, reAuth }) {
  const { financeTranslation: t } = useTranslations();
  return (
    <Box layerStyle='inst-icon' flexShrink={0}>
      <HStack width='100%' height='100%' alignItems='center' gap={3}>
        <Box
          borderRadius='8px'
          border='1px solid #F2F2F2'
          width='67px'
          height='67px'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <InstLogo inst={inst} size={35} color={'#828B97'} />
        </Box>
        <VStack ml={4} alignItems='start'>
          <Text fontSize='16px' fontWeight={500}>
            {inst.institution_name}
          </Text>
          {inst.error === LINKED_CARDS_ERROR.NEED_AUTHENTICATION && (
            <Badge colorScheme='red' variant='subtle' size='xl'>
              {t.disconnected}
            </Badge>
          )}
        </VStack>
        {inst.error === LINKED_CARDS_ERROR.NEED_AUTHENTICATION && (
          <Button ml='auto' variant='ghost' p={0} onClick={() => reAuth(inst.institution_id)}>
            <Circle bg='red.100' size={7}>
              <FaChevronRight color='#822727' size={14} />
            </Circle>
          </Button>
        )}
      </HStack>
    </Box>
  );
}

InstIcon.propTypes = {
  inst: PropTypes.object,
  reAuth: PropTypes.func,
};

export function AccountCardsPage() {
  const [loaded, loadedCtrl] = useBoolean(false);
  const { linkCards, institutions, reAuth } = useFinance();
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();
  const { region } = useGlobalForest(globalState);
  const { financeTranslation: t } = useTranslations();

  useEffect(() => {
    loadedCtrl.on();
  }, [loadedCtrl]);

  const responsive = useBreakpointValue(
    {
      base: {
        nav: 'small',
        stackDir: 'column',
        wrap: 'nowrap',
        pageJustify: 'flex-start',
        align: 'center',
      },
      md: { nav: 'large', stackDir: 'row', wrap: 'wrap', pageJustify: 'stretch' },
      lg: { nav: 'large', stackDir: 'row', wrap: 'wrap', pageJustify: 'stretch' },
    },
    { fallback: 'md' }
  );

  const linkBankAccount = useCallback(() => {
    switch (region) {
      case REGIONS.US: {
        linkCards();
        break;
      }
      case REGIONS.UK: {
        linkBankAccountState.do.set_postModalUrl(ACCOUNT_CARDS_ROUTE);
        linkBankAccountState.do.set_isModalOpen(true);
        break;
      }
      default:
        return;
    }
  }, [region, linkCards]);

  if (!isLoggedIn) {
    navigate('/');
    return null;
  }

  if (loaded) {
    return (
      <>
        <Stack height='100%'>
          <Box data-role='page-container' p={['20px', '20px', '40px']} width='100%'>
            <Stack
              direction={responsive.stackDir}
              w='100%'
              justify={responsive.pageJustify}
              spacing={['20px', '40px']}
            >
              <UserAndCard />
              <Card flexShrink={1} flex={1}>
                <CardHeader>
                  <HStack w='100%' justify='space-between'>
                    <Text>{t.myWallet}</Text>
                    <Button size='sm' onClick={linkBankAccount} leftIcon={<FaPlus />}>
                      {t.addCard}
                    </Button>
                  </HStack>
                </CardHeader>
                <CardBody px={'40px'}>
                  <Heading variant='card-subheader'>Cards</Heading>
                  <Box mx={'12px'}>
                    <Flex
                      width='100%'
                      data-role='card-wrapper'
                      direction={responsive.stackDir}
                      wrap={responsive.wrap}
                      alignItems={responsive.align}
                    >
                      {institutions.map((inst) => (
                        <CreditCardGroup key={inst.institution_id} institution={inst} />
                      ))}
                      <AddCreditCardButton onClick={linkBankAccount} />
                    </Flex>
                  </Box>
                  <Heading variant='card-subheader'>Bank Accounts</Heading>
                  <Flex
                    direction={responsive.stackDir}
                    flexWrap={responsive.wrap}
                    alignItems={responsive.align}
                    w='100%'
                    data-role='insts-wrapper'
                  >
                    {institutions?.map((inst) => (
                      <InstIcon key={inst.institution_id} inst={inst} reAuth={reAuth} />
                    ))}
                  </Flex>
                </CardBody>
              </Card>
            </Stack>
          </Box>
        </Stack>
      </>
    );
  }
  return null;
}
