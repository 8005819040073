import * as PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { fetchCards, fetchLinkToken, setToken } from '~/api/institutions';
import useIsLoggedIn from '~/hooks/useIsLoggedIn';
import { CARD_ACCESS_TOKEN_URL, CARDS_API_URL } from '~/lib/constants';
import { CustomError, handleError } from '~/lib/errors';
import fetchUrl from '~/lib/fetchUrl';
import { neutralizeEvent, plural } from '~/lib/helpers';
import { logEvent } from '~/lib/logEvent';
import { message, TOAST_STATUS } from '~/lib/message';
import accountState from '../account-state';

const CARD_IMAGE_URL_ACTIVE = '/img/bank-card-active.svg';
const CARD_IMAGE_URL_INACTIVE = '/img/bank-card-inactive.svg';

/**
 * @typedef {Object} Cards
 * @property {string} cardNumber
 * @property {string} cardType
 */

/**
 * @typedef {Object} Institution
 * @property {string} institution_id
 * @property {string} institution_name
 * @property {string} color
 */

export const FinanceContext = createContext();

// importing useTranslations does not work here

const FinanceProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [plaidModalIsOpen, setPlaidModalIsOpen] = useState(false);
  const [linkToken, setLinkToken] = useState('');
  const isLoggedIn = useIsLoggedIn();
  const hasLoaded = useRef(false);

  const cards = useMemo(
    () => institutions?.map((inst) => inst.linked_cards).flat() ?? [],
    [institutions]
  );

  const statusText = useMemo(() => {
    const cardCount = cards?.length;
    const institutionCount = institutions?.length;

    const firstInst = institutions?.[0];

    if (!cardCount) {
      return 'Don’t miss out! Link an account to start earning cash!';
    }

    // Too complicated to translate
    return `You have linked ${plural(
      {
        single: 'one card',
        two: 'two cards',
        many: `${cardCount} cards`,
      },
      cardCount
    )}
      from ${plural(
        {
          single: firstInst?.institution_name ?? 'one bank',
          two: 'two banks',
          many: `${institutionCount} banks`,
        },
        institutionCount
      )}. 
      You can link ${plural(
        {
          single: 'a second card',
          many: 'another card',
        },
        cardCount
      )} to earn more cash. `;
  }, [cards, institutions]);

  const cardImageUrl = useMemo(
    () => (cards?.length > 0 ? CARD_IMAGE_URL_ACTIVE : CARD_IMAGE_URL_INACTIVE),
    [cards]
  );

  const fetchInstitutions = useCallback(async () => {
    setIsLoading(true);

    try {
      const data = await fetchCards();

      if (!Array.isArray(data?.linked_institutions)) {
        throw new CustomError({
          title: 'Bad data for linked institutions',
          description: 'Cannot find linked institutions',
        });
      }

      setInstitutions(data.linked_institutions);
    } catch (error) {
      handleError(error, 'Error fetching cards');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const reAuth = useCallback(
    async (instId, { onSuccess, onExit } = {}, e) => {
      if (e) {
        neutralizeEvent(e);
      }
      logEvent('plaid', { action: 'link_card' });

      if (accountState.$.isExpired()) {
        message({
          title: 'Cannot Link',
          description: 'Your session has expired; please log in again',
          status: 'error',
        });
        return;
      }

      try {
        const response = await fetchUrl(`${CARDS_API_URL}auth-token/${instId}`, {
          accountState,
        });

        const info = await response.json();

        if (info && typeof info === 'object' && 'link_token' in info) {
          setLinkToken(info.link_token);
        }

        setPlaidModalIsOpen(true);
        // Plaid is a global library
        // eslint-disable-next-line no-undef
        Plaid.create({
          token: info.link_token,
          onSuccess: async () => {
            logEvent('plaid', { action: 'link_success' });
            await fetchInstitutions();
            setPlaidModalIsOpen(false);
            onSuccess?.();
          },
          onExit() {
            setPlaidModalIsOpen(false);
            onExit?.();
          },
        }).open();
      } catch (err) {
        message({
          title: 'Cannot Link',
          description: 'token error:' + err.message,
          status: 'error',
        });
      }
    },
    [fetchInstitutions]
  );

  const afterLink = useCallback(
    async ([public_token, metadata]) => {
      const { institution } = metadata;
      setPlaidModalIsOpen(false);

      try {
        await setToken(public_token, institution);

        message({
          title: 'Card Linked',
          description: 'Linked card from ' + institution.name,
          status: TOAST_STATUS.SUCCESS,
        });
        return await fetchInstitutions();
      } catch (error) {
        handleError(error, 'Card NOT linked');
      }
    },
    [fetchInstitutions]
  );

  const linkCards = useCallback(
    async (e, onLink) => {
      if (e) {
        e.preventDefault();
      }
      logEvent('plaid', { action: 'link_card' });

      try {
        if (accountState.$.isExpired()) {
          throw new CustomError({
            title: 'Session expired',
            description: 'Please log in again',
          });
        }

        const data = await fetchLinkToken();
        if (data && typeof data === 'object' && 'link_token' in data) {
          setLinkToken(data.link_token);
        }

        setPlaidModalIsOpen(true);
        // eslint-disable-next-line no-undef
        Plaid.create({
          token: data.link_token,
          onSuccess: (...args) => {
            logEvent('plaid', { action: 'link_success' });
            afterLink(args, onLink);
          },
          onExit() {
            setPlaidModalIsOpen(false);
          },
        }).open();
      } catch (error) {
        handleError(error, 'Error linking card');
      }
    },
    [afterLink]
  );

  const unlinkCard = useCallback(
    async (inst, instId) => {
      try {
        await fetchUrl(CARD_ACCESS_TOKEN_URL + instId, {
          accountState,
          method: 'DELETE',
        });

        const description = inst?.institution_name
          ? 'Your card for ' +
            inst?.institution_name +
            ' is no longer being tracked by our rewards service'
          : 'Your card is no longer being tracked by our rewards service';

        message({
          title: 'Card unlinked',
          status: TOAST_STATUS.SUCCESS,
          description,
        });

        return fetchInstitutions();
      } catch (error) {
        handleError(error, 'Cannot unlink card');
      }
    },
    [fetchInstitutions]
  );

  useEffect(() => {
    if (isLoggedIn && !hasLoaded.current) {
      fetchInstitutions();
      hasLoaded.current = true;
    }
  }, [isLoggedIn, fetchInstitutions]);

  const values = useMemo(
    () => ({
      cards,
      cardImageUrl,
      institutions,
      reAuth,
      plaidModalIsOpen,
      linkToken,
      linkCards,
      unlinkCard,
      isLoading,
      statusText,
    }),
    [
      cards,
      cardImageUrl,
      institutions,
      plaidModalIsOpen,
      linkCards,
      unlinkCard,
      reAuth,
      linkToken,
      isLoading,
      statusText,
    ]
  );
  return <FinanceContext.Provider value={values}>{children}</FinanceContext.Provider>;
};

export default FinanceProvider;

FinanceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
